












import Vue from "vue";
export default Vue.extend({
  data: () => {
    return {
      isInputActive: false,
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    displayPercentage: {
      get(): String {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          return this.value.toString();
        } else {
          if (this.value == null) {
            // the value was never set
            return parseFloat("0").toFixed(2).toString();
          } else {
            return parseFloat(this.value.toString()).toFixed(2).toString();
          }
        }
      },
      set: function (newVal: number) {
        let returnVal = null;

        if (!isNaN(newVal)) {
          returnVal = parseFloat(newVal.toString());

          // Ensure that it is not NaN
          if (isNaN(returnVal)) {
            returnVal = 0;
          }
        }

        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit("input", returnVal);
        this.$emit("change", returnVal);
      },
    },
  },
  methods: {
    validate() {
      if (this.value == null) {
        return "Please check your input.";
      }

      return true;
    },
  },
});
