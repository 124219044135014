<template>
  <S2SForm
    :title="
      discountProducts.length > 0
        ? `${discountProducts[0].organisation.name} Special Discounts`
        : 'Customer Discount'
    "
  >
    <!-- <vue-page-container title="Default Discount" toolbar-class="elevation-1" color="white" toolbarColor="white" :containerProps="{ 'elevation-1': true }">
			<v-btn color="accent" slot="toolbar-content">Set Prices</v-btn>
			<v-text-field label="Discount Percentage"></v-text-field>
		</vue-page-container>

		<br />-->

    <v-text-field
      label="Filter Product"
      v-model="searchVal"
      single-line
      hide-details
      append-icon="search"
      clearable
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="discountProducts"
      :loading="loading"
      class="elevation-1"
      :options.sync="_pagination"
      :server-items-length="totalItems"
      :search="searchVal"
    >
      <template v-slot:item.price="{ item }">{{
        formatText(item.price)
      }}</template>
      <template v-slot:item.discountR="{ item }">
        <S2SCurrencyInput
          :ref="`discountR${item.id}`"
          :value="item.price - (item.price * item.discount) / 100"
          @input="onDiscountRandChange($event, item)"
          symbol="R"
        ></S2SCurrencyInput>
      </template>
      <template v-slot:item.discountPerc="{ item }">
        <v-layout align-center>
          <v-flex xs8>
            <S2SPercentageInput
              :ref="`discountPerc${item.id}`"
              v-model="item.discount"
              @input="onDiscountChange($event, item.id)"
            ></S2SPercentageInput>
          </v-flex>
          <v-flex>
            <v-btn
              color="primary"
              :disabled="!dirtyDiscounts[item.id]"
              @click="onDiscountUpdated(item)"
              class="ml-2 float-right"
              >Update</v-btn
            >
          </v-flex>
        </v-layout>
      </template>
    </v-data-table>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import { Utils } from "@/utils/";
  import S2SPercentageInput from "@/components/S2SPercentageInput.vue";

  export default Vue.extend({
    name: "MyCustomer",

    components: { S2SPercentageInput },

    props: {
      connectionId: {
        type: [Number, String],
        default: 0,
      },
    },

    data: function () {
      return {
        deleteConfirm: false,

        customer: "",
        discounts: {},
        dirtyDiscounts: {},

        headers: [
          { text: "SKU", value: "sku", sortable: false },
          { text: "Product Name", value: "name", sortable: false },
          {
            text: "Discounted Base Price",
            value: "price",
            type: "currency",
            sortable: false,
          },
          { text: "Description", value: "shortDescription", sortable: false },
          { text: "Selling Price", value: "discountR", sortable: false },
          {
            text: "Additional Discount",
            value: "discountPerc",
            sortable: false,
          },
        ],

        searchVal: "",
        timeoutId: 0,
      };
    },

    computed: {
      _pagination: {
        get() {
          return this.pagination;
        },
        set(value) {
          this.$store.dispatch("connections/paginate", value);
        },
      },
      discountProducts: function () {
        return this.$store.getters["connections/discountProducts"];
      },
      loading: function () {
        return this.$store.state.connections.loading;
      },
      pagination: function () {
        return this.$store.state.connections.pagination;
      },
      totalItems: function () {
        return this.$store.state.connections.totalItems;
      },
      activeShopToken: function () {
        return this.$store.state.shops.activeShopToken;
      },
    },

    watch: {
      activeShopToken: {
        deep: true,
        handler() {
          this.$router.push({ name: "my-customers" });
        },
      },

      pagination: {
        deep: true,
        handler(newx, oldx) {
          this.$store.dispatch("connections/fetchDiscountProducts", {
            connectionId: this.connectionId,
          });
        },
      },

      searchVal: function () {
        clearTimeout(this.timeoutId);
        this.timeoutId = window.setTimeout(() => {
          if (this.searchVal === "") {
            this.$store.dispatch("connections/fetchDiscountProducts", {
              connectionId: this.connectionId,
            });
          } else {
            this.$store.dispatch("connections/fetchDiscountProducts", {
              connectionId: this.connectionId,
              search: this.searchVal,
            });
          }
        }, 500);
      },
    },

    methods: {
      onDiscountChange(value, productId) {
        this.dirtyDiscounts[productId] = true;
        this.dirtyDiscounts = JSON.parse(JSON.stringify(this.dirtyDiscounts));
      },
      onDiscountRandChange(discountPrice, product) {
        product.discount = 100 - (discountPrice / product.price) * 100;

        this.dirtyDiscounts[product.id] = true;
        this.dirtyDiscounts = JSON.parse(JSON.stringify(this.dirtyDiscounts));
      },
      async onDiscountUpdated(data) {
        this.dirtyDiscounts[data.id] = false;
        this.dirtyDiscounts = JSON.parse(JSON.stringify(this.dirtyDiscounts));

        this.$store.dispatch("connections/updateProductDiscount", {
          connectionId: this.connectionId,
          productId: data.id,
          amount: data.discount,
        });
      },
      formatText(value) {
        return Utils.formatText(value, Utils.TextType.CURRENCY);
      },
    },

    mounted: function () {
      this._pagination = { ...this._pagination, page: 1 };
    },
  });
</script>
