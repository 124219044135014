var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('S2SForm',{attrs:{"title":_vm.discountProducts.length > 0
        ? ((_vm.discountProducts[0].organisation.name) + " Special Discounts")
        : 'Customer Discount'}},[_c('v-text-field',{attrs:{"label":"Filter Product","single-line":"","hide-details":"","append-icon":"search","clearable":""},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.discountProducts,"loading":_vm.loading,"options":_vm._pagination,"server-items-length":_vm.totalItems,"search":_vm.searchVal},on:{"update:options":function($event){_vm._pagination=$event}},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm.formatText(item.price)))]}},{key:"item.discountR",fn:function(ref){
        var item = ref.item;
return [_c('S2SCurrencyInput',{ref:("discountR" + (item.id)),attrs:{"value":item.price - (item.price * item.discount) / 100,"symbol":"R"},on:{"input":function($event){return _vm.onDiscountRandChange($event, item)}}})]}},{key:"item.discountPerc",fn:function(ref){
        var item = ref.item;
return [_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{attrs:{"xs8":""}},[_c('S2SPercentageInput',{ref:("discountPerc" + (item.id)),on:{"input":function($event){return _vm.onDiscountChange($event, item.id)}},model:{value:(item.discount),callback:function ($$v) {_vm.$set(item, "discount", $$v)},expression:"item.discount"}})],1),_c('v-flex',[_c('v-btn',{staticClass:"ml-2 float-right",attrs:{"color":"primary","disabled":!_vm.dirtyDiscounts[item.id]},on:{"click":function($event){return _vm.onDiscountUpdated(item)}}},[_vm._v("Update")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }